var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Login"},[(_vm.mobileFlag)?_c('div',{staticClass:"loginTop"},[_c('img',{staticClass:"title_logo",attrs:{"src":require("../../assets//images/CwLogo.svg"),"alt":""}}),_c('div',{staticClass:"title_icons"},[_c('div',{staticClass:"menu_icon",on:{"click":function($event){return _vm.changeLanguage(_vm.languageTxt)}}},[_c('img',{attrs:{"src":require("../../assets/images/LanguageIcon.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.languageTxt == 'zh' ?'CN':'EN')+" ")])])]):_vm._e(),_c('div',{staticClass:"loginDiv"},[_c('div',{staticClass:"loginLeft"},[_c('div',{staticClass:"loginTitle"},[_c('span',{staticClass:"loginTxt"},[_vm._v(" "+_vm._s(_vm.$t('login_title'))+" ")]),(!_vm.mobileFlag)?_c('div',{staticClass:"loginLanguage",on:{"click":function($event){return _vm.changeLanguage(_vm.languageTxt)}}},[_c('svg-icon',{staticClass:"languageIcon",attrs:{"icon-class":"LanguageIcon"}}),_c('span',{staticClass:"languageTxt"},[_vm._v(_vm._s(_vm.languageTxt == 'zh'?'CN':'EN'))])],1):_vm._e()]),_c('el-form',{ref:"loginForm",staticClass:"loginForm",attrs:{"label-position":"top","label-width":"80px","model":_vm.loginForm}},[_c('el-form-item',{attrs:{"label":_vm.$t('login_username'),"prop":"loginID","rules":[
                    { required: true, message: _vm.$t('login_emailNotNull'), trigger: 'blur' },
                    { type: 'email', message: _vm.$t('login_emailError'), trigger: ['blur', 'change'] }
                    ]}},[_c('el-input',{model:{value:(_vm.loginForm.loginID),callback:function ($$v) {_vm.$set(_vm.loginForm, "loginID", $$v)},expression:"loginForm.loginID"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('login_password'),"prop":"password","rules":[
                    { required: true, message: _vm.$t('login_passwordNotNull'), trigger: 'blur' }, 
                    ]}},[_c('el-input',{attrs:{"autocomplete":"off","type":"password","show-password":""},model:{value:(_vm.loginForm.password),callback:function ($$v) {_vm.$set(_vm.loginForm, "password", $$v)},expression:"loginForm.password"}})],1)],1),_c('el-button',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.fullscreenLoading),expression:"fullscreenLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"loginBtn",on:{"click":_vm.login}},[_vm._v(_vm._s(_vm.$t('login_title')))]),_c('div',{staticClass:"loginTips"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('login_tips1'))+" "),_c('b',{attrs:{"href":""},on:{"click":function($event){return _vm.goReg()}}},[_vm._v(_vm._s(_vm.$t('login_tips2')))])]),_c('b',{attrs:{"href":""},on:{"click":function($event){return _vm.goForget()}}},[_vm._v(_vm._s(_vm.$t('login_tips3')))])])],1),_vm._m(0)]),_c('div',{staticClass:"loginBottom"},[_vm._v(" "+_vm._s(_vm.$t('login_bottomTips'))+" ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loginRight"},[_c('img',{attrs:{"src":require("../../assets/images/LoginImg.png"),"alt":""}})])
}]

export { render, staticRenderFns }