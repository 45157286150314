<template>
    <div class="Login">
        <div class="loginTop" v-if="mobileFlag">
            <!-- <div class="title_logo"> -->
                <img class="title_logo" src="../../assets//images/CwLogo.svg" alt="">
            <!-- </div> -->
            <div class="title_icons">
                <div class="menu_icon" @click="changeLanguage(languageTxt)">
                    <img src="../../assets/images/LanguageIcon.svg" alt="">
                    {{ languageTxt == 'zh' ?'CN':'EN'  }}
                    
                </div>
            </div>
        </div>
        <div class="loginDiv">
            <div class="loginLeft">
                <div class="loginTitle">
                    <span class="loginTxt">
                        {{ $t('login_title') }}
                    </span>
                    <div class="loginLanguage" @click="changeLanguage(languageTxt)" v-if="!mobileFlag">
                        <svg-icon class="languageIcon" icon-class="LanguageIcon"></svg-icon>
                        <span class="languageTxt">{{ languageTxt == 'zh'?'CN':'EN' }}</span>
                    </div>
                </div>
                <el-form class="loginForm" ref="loginForm" label-position="top" label-width="80px" :model="loginForm">
                    <el-form-item :label="$t('login_username')" prop="loginID" :rules="[
                        { required: true, message: $t('login_emailNotNull'), trigger: 'blur' },
                        { type: 'email', message: $t('login_emailError'), trigger: ['blur', 'change'] }
                        ]">
                        <el-input v-model="loginForm.loginID"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('login_password')" prop="password"  :rules="[
                        { required: true, message: $t('login_passwordNotNull'), trigger: 'blur' }, 
                        ]">
                        <el-input v-model="loginForm.password" autocomplete="off"  type="password" show-password></el-input>
                    </el-form-item>
                </el-form>
                <el-button class="loginBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="login">{{ $t('login_title') }}</el-button>
                <div class="loginTips">
                    <span>
                        {{ $t('login_tips1') }}
                        <b href="" @click="goReg()">{{ $t('login_tips2') }}</b>
                    </span>
                    <b href="" @click="goForget()">{{ $t('login_tips3') }}</b>
                </div>
            </div>
            <div class="loginRight">
                 <img  src="../../assets/images/LoginImg.png" alt="">
            </div>
        </div>
        <div class="loginBottom">
            {{ $t('login_bottomTips') }}
        </div>
    </div>
  </template>
    
  <script>
  // @ is an alias to /src
  import { mapState } from "vuex";

  
  export default {
    name: "Login",
    computed: {
      ...mapState(["mobileFlag", "lang"]),
    },
    data() {
      return {
        languageTxt:'',
        loginForm:{
            loginID:'',
            password:''
        },
        fullscreenLoading:false
      };
    },
    created() {
        this.languageTxt =  this.lang
        // this.changeLanguage(this.lang)
        // this.getCppyWealth()
    },
    methods: {
        goReg(){
            this.$router.push({ path: '/Register'});
        },
        goForget(){
            this.$router.push({ path: '/ForgetPassword'});
        },
        changeLanguage(tag){
            
            if(tag == 'zh'){
                this.$i18n.locale = "en";
	            // localStorage.setItem('localeLanguage', lang)
                this.$store.commit("setLang",'en');
                this.languageTxt = 'en'
            }else{
                this.$i18n.locale = "zh";
                this.$store.commit("setLang",'zh');
                this.languageTxt = 'zh'

            }
        },
        login(){
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.fullscreenLoading = true
                    let params = {
                        loginID:this.loginForm.loginID.trim(),
                        password: this.Rsa.encrypt(this.loginForm.password.trim()),
                    }
                    this.$axios
                    .post("/Api/Login/Login", params)
                    .then((res) => {
                        let dt = res.data;
                        if (dt.isSuccess) {
                            dt.result.userInfo.gmt = dt.result.gmt
                            this.$store.commit("setToken", dt.result.token);
                            this.$store.commit("setUserInfo", dt.result.userInfo);
                            this.getUserType()
                            
                        } else {
                            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                        }
                    }).catch((error) =>{
                        console.log(error)
                        
                            // this.$notify.error({title: this.$t('MyFollowers_prompt'), message:this.$t('errorCommon')})

                       
                    }).finally(()=>{
                        this.fullscreenLoading = false
                    })
                   
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getUserType(){
            this.$axios
            .get("/Api/home/usertype")
            .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
                this.isFollower = dt.result.isFollower
                this.isSignal = dt.result.isSignal
                if(dt.result.isFollower || dt.result.isSignal){
                    this.$router.push({ path: '/HomeIndex'});
                }else{
                    this.$router.push({ path: '/FollowInvited'});
                }
                this.$notify.success({title: this.$t('nofity_success'), message:this.$t('loginSuccess')});
                // if(!this.isFollower && !this.isSignal){
                //   this.$router.push({ path: '/MyFollow' });
                // }
                
            } else {
                this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
            }) .finally(() => {
                this.fullscreenLoading = false;
            });
        },
    },
    
  };
  </script>
  <style scoped lang="less">
 .Login{
    background: #D5EAFD;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    
    .loginDiv{
        margin: 0  auto 20px auto;
        max-width: 1200px;
        max-height: 700px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 80px;
        .loginLeft{
            width: calc(50% - 160px);
            background: #fff;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            padding: 130px 80px;
            .loginTitle{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .loginTxt{
                    color: #000;
                    font-family: 'PingFangSC-Semibold';
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
                .loginLanguage{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    .languageIcon{
                        width: 20px;
                        height: 20px;
                    }
                    .languageTxt{
                        line-height: 20px;
                        color: #0B205D;
                        text-align: right;
                        font-family: 'PingFangSC-Medium';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        margin-left: 5px;
                    }
                }
            }
            .loginForm{
                margin-top: 40px;
            }
            .loginTips{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 20px;
                span{
                    color: #000;
                    font-family: 'PingFangSC-Medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                }
                b{
                    color: #4D71FF;
                    text-align: right;
                    font-family: 'PingFangSC-Medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .loginRight{
            
            width: 50%;
            img{
                height: 100%;
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
            }
        }
        .loginBtn{
            border-radius: 4px;
            background: #0F1845;
            display: flex;
            width: 440px;
            padding: 10px 30px;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-family: 'PingFangSC-Medium';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 40px;

        }
    }
    .loginBottom{
        color: #0F1845;
        font-family: 'PingFangSC-Medium';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 1200px;
        margin: 50px  auto 0 auto;
        padding-bottom: 30px;
        opacity: 0.3;
    }
 }

 @media screen and (max-width:767px) {
    .Login{
        background: #fff;
        .loginDiv{
            position: relative;
            display: flex;
            flex-direction: column;
            top: 0;
            .loginLeft{
                width: calc(100% - 40px);
                padding: 30px 20px;
                .loginTitle{
                    .loginTxt{
                        font-size: 18px;
                    }
                    .loginForm{
                        margin-top: 30px;
                    }
                    
                }
                .loginTips{
                    span,b{
                        font-size: 14px;
                    }
                }
            }
            .loginBtn{
                width: 100%;
                background-color: #4D71FF;
                font-size: 14px;
            }
            
            .loginRight{
                display: none;
            }
        }
        .loginBottom{
            padding: 20px 20px 35px 20px;
            bottom: 0;
            background: #F4F4F4;
            color: #0F1845;
            font-size: 12px;
            position: relative;
        }
    }
 }
  </style>
    